/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const V1SearchResultViewType = {
    Unspecified: 'SEARCH_RESULT_VIEW_TYPE_UNSPECIFIED',
    LocalProfile: 'SEARCH_RESULT_VIEW_TYPE_LOCAL_PROFILE'
} as const;
export type V1SearchResultViewType = typeof V1SearchResultViewType[keyof typeof V1SearchResultViewType];


export function V1SearchResultViewTypeFromJSON(json: any): V1SearchResultViewType {
    return V1SearchResultViewTypeFromJSONTyped(json, false);
}

export function V1SearchResultViewTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchResultViewType {
    return json as V1SearchResultViewType;
}

export function V1SearchResultViewTypeToJSON(value?: V1SearchResultViewType | null): any {
    return value as any;
}

