import { type StepParamsTypeMap } from './params'

export const FeedStepKeys = [
  'home', // 홈
  'searchDiscovery', // 검색 인풋 클릭시 검색 타이핑 화면
  'searchResult', // 검색 화면
  'preview', // 미리보기 화면
  'searchedPreview', // search step에서의 preview
  'storyPreview', // 이야기 미리보기
  'externalPage', // 로컬프로필 홈과 같은 외부 페이지
] as const

export const OtherStepKeys = [] as const
export const LocalStepKeys = [...FeedStepKeys, ...OtherStepKeys] as const

export type FeedStepKeys = (typeof FeedStepKeys)[number]
export type OtherStepKeys = (typeof OtherStepKeys)[number]
export type LocalMapStepKeys = (typeof LocalStepKeys)[number]

type BaseStep<K extends LocalMapStepKeys> = {
  step: K
  params: StepParamsTypeMap[K]
}

type HomeStep = BaseStep<'home'>

type SearchTypingStep = BaseStep<'searchDiscovery'>

type SearchStep = BaseStep<'searchResult'>

type PreviewStep = BaseStep<'preview'>

type SearchedPreviewStep = BaseStep<'searchedPreview'>

type StoryPreviewStep = BaseStep<'storyPreview'>

type externalPageStep = {
  step: 'externalPage'
  params: {
    localProfileId: string
  }
}

export type LocalMapSteps =
  | HomeStep
  | SearchTypingStep
  | SearchStep
  | PreviewStep
  | SearchedPreviewStep
  | StoryPreviewStep
  | externalPageStep
